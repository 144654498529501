import { locale } from 'bv-i18n';
import { isRoot } from 'bv-helpers/locale';

const absolutePathRegex = /^(?:[a-z]+:)?\/\//i;

export default (link) => {
  if (absolutePathRegex.test(link)) return link;
  if (isRoot(link)) return window.BV.v('rootPath');

  return `/${locale()}${link}`;
};
