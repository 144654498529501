import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import withLazyLoad from 'bv-lazy-load';
import { hasSportsbook } from 'global-config';

import NavigationButtons from './navigation_buttons';
import PanelStylesApplicator from './panel_styles_applicator';
import CloseLayer from './close_layer';
import LuckyDipBetslip from './lucky_dip_betslip';

let SportsbookBetslip = hasSportsbook ? withLazyLoad()('sportsbookBetslip') : () => null;

const FloatingNavigation = () => {
  const luckyDipLoaded = useSelector((state) => !!state.luckyDipBetslip);
  const [showLDBetslip, setShowLDBetslip] = useState(luckyDipLoaded);

  useEffect(() => {
    if (luckyDipLoaded && !showLDBetslip) {
      setShowLDBetslip(true);
    }
  }, [luckyDipLoaded, showLDBetslip]);

  if (!hasSportsbook && showLDBetslip) {
    SportsbookBetslip = withLazyLoad()('sportsbookBetslip');
  }

  return (
    <div className="floating-nav">
      <PanelStylesApplicator />
      <NavigationButtons />
      <CloseLayer />
      <SportsbookBetslip betslipType="mini-betslip" />
      {showLDBetslip && <LuckyDipBetslip />}
    </div>
  );
};

export default FloatingNavigation;
