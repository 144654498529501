import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'underscore';
import { useSelector } from 'react-redux';
import NavigationButtonView from './navigation_button_view';
import { makeIsActive, getComponentDisabled } from '../selectors';

const NavigationButtonContainer = (props) => {
  const { component } = props;
  const getIsActive = useMemo(makeIsActive, []);
  const active = useSelector((state) => getIsActive(state, { component }));
  const disabled = useSelector((state) => getComponentDisabled(state, { component }, isEqual));

  return (
    <NavigationButtonView disabled={disabled} active={active} {...props} />
  );
};

NavigationButtonContainer.propTypes = {
  label: PropTypes.string.isRequired,
  component: PropTypes.string,
  icon: PropTypes.string.isRequired,
  click: PropTypes.func,
  hasBadge: PropTypes.bool,
  hasTooltip: PropTypes.bool,
  cta: PropTypes.instanceOf(Object).isRequired,
  layout: PropTypes.string.isRequired,
  positions: PropTypes.instanceOf(Object).isRequired,
};

NavigationButtonContainer.defaultProps = {
  component: '',
  click: () => {},
  hasTooltip: false,
  hasBadge: false,
};

export default NavigationButtonContainer;
