import PropTypes from 'prop-types';

const Padlock = ({ padlockText }) => (
  <div className="smartbanner__info-padlock">
    <div className="smartbanner__info-padlock__icon" />
    {
      padlockText && (
      <span className="smartbanner__info-padlock__text">
        {padlockText}
      </span>
      )
    }
  </div>
);

Padlock.propTypes = {
  padlockText: PropTypes.string.isRequired,
};

export default Padlock;
