export const OPEN_MENU = 'layoutApps/floatingNavigation/OPEN_MENU';
export const OPEN_RIGHT = 'layoutApps/floatingNavigation/OPEN_RIGHT';
export const RESET = 'layoutApps/floatingNavigation/RESET';

const SET_BETSLIP_BETS_NUMBER = 'layoutApps/floatingNavigation/SET_BETSLIP_BETS_NUMBER';
const SET_CASHOUT_BETS_NUMBER = 'layoutApps/floatingNavigation/SET_CASHOUT_BETS_NUMBER';
const INCREMENT_CASHOUT_BETS_NUMBER = 'layoutApps/floatingNavigation/INCREMENT_CASHOUT_BETS_NUMBER';
const DECREMENT_CASHOUT_BETS_NUMBER = 'layoutApps/floatingNavigation/DECREMENT_CASHOUT_BETS_NUMBER';
const SET_BETSLIP_TOOLTIP = 'layoutApps/floatingNavigation/SET_BETSLIP_TOOLTIP';

export const reset = () => ({
  type: RESET,
});

export const openMenu = () => ({
  type: OPEN_MENU,
});

export const openRight = () => ({
  type: OPEN_RIGHT,
});

export const setBetslipBetsNumber = (betslipbBetsNumber) => ({
  type: SET_BETSLIP_BETS_NUMBER,
  betslipbBetsNumber,
});

export const setBetslipTooltip = (betslipbTooltip, opts = { duration: 4000, className: '' }) => ({
  type: SET_BETSLIP_TOOLTIP,
  betslipbTooltip,
  betslipbTooltipDuration: opts.duration,
  betslipbTooltipClassName: opts.className,
});

export const setCashoutBetsNumber = (cashoutBetsNumber) => ({
  type: SET_CASHOUT_BETS_NUMBER,
  cashoutBetsNumber,
});

export const incrementCashoutBetsNumber = () => ({
  type: INCREMENT_CASHOUT_BETS_NUMBER,
});

export const decrementCashoutBetsNumber = () => ({
  type: DECREMENT_CASHOUT_BETS_NUMBER,
});

const panelInitialState = {
  stateLeftPanel: false,
  stateRightPanel: false,
};

export const initialState = {
  ...panelInitialState,
  betslipbBetsNumber: 0,
  cashoutBetsNumber: 0,
  betslipbTooltip: '',
  betslipbTooltipDuration: 4000,
  betslipbTooltipClassName: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        ...panelInitialState,
      };
    case OPEN_MENU:
      return {
        ...state,
        stateLeftPanel: !state.stateLeftPanel,
      };
    case OPEN_RIGHT:
      return {
        ...state,
        stateRightPanel: !state.stateRightPanel,
      };
    case SET_BETSLIP_BETS_NUMBER:
      return {
        ...state,
        betslipbBetsNumber: action.betslipbBetsNumber,
      };
    case SET_BETSLIP_TOOLTIP:
      return {
        ...state,
        betslipbTooltip: action.betslipbTooltip,
        betslipbTooltipDuration: action.betslipbTooltipDuration,
        betslipbTooltipClassName: action.betslipbTooltipClassName,
      };
    case SET_CASHOUT_BETS_NUMBER:
      return {
        ...state,
        cashoutBetsNumber: action.cashoutBetsNumber,
      };
    case INCREMENT_CASHOUT_BETS_NUMBER:
      return {
        ...state,
        cashoutBetsNumber: state.cashoutBetsNumber + 1,
      };
    case DECREMENT_CASHOUT_BETS_NUMBER:
      return {
        ...state,
        cashoutBetsNumber: state.cashoutBetsNumber - 1,
      };
    default:
      return state;
  }
};
