import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Ambassador } from 'bv-components';

// TODO: Use ambassador-component to remove the padding added by left-component
// We could pass the className to the ambassador, as the ambassador will need a wrapper
// It will depend on Ambassador templating, yet to be done

const AmbassadorComponent = ({ ambassador }) => ambassador && (
  <div className="left-component ambassador-component">
    <Ambassador ambassador={ambassador} />
  </div>
);

AmbassadorComponent.propTypes = {
  ambassador: PropTypes.instanceOf(Object),
};

AmbassadorComponent.defaultProps = {
  ambassador: null,
};

const mapStateToProps = (state) => ({
  ambassador: state.siteFooter?.ambassador,
});

export default connect(mapStateToProps)(AmbassadorComponent);
