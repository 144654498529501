/* eslint import/prefer-default-export: off */
import { createSelector } from 'reselect';
import { property, isEqual } from 'underscore';

const getActiveComponent = property(['rightSidebar', 'component']);
const getComponent = (state, component) => component;

export const getComponentDisabled = (state, { component }) => {
  if (component === 'betslip') {
    const betslip = state?.betslip;
    const betslipInited = betslip?.inited;
    const outcomeIds = betslip?.outcomeIds || [];
    const submitResponseSuccess = betslip?.submitResponse?.success;
    return betslipInited && (outcomeIds.length === 0 && !submitResponseSuccess);
  }
  return false;
};

export const makeIsActive = () => createSelector(
  [getActiveComponent, getComponent],
  isEqual,
);
