import ModalApp from './components/modal_app';

import reducer from './reducers';
import { addModal, removeModal } from './actions';

import modalTypes from './modal_types';

const { store, addReducers, addActionDispatchers } = window.reduxState;

addReducers({
  modal: reducer,
});

addActionDispatchers('modal', { removeModal });

window.ModalHelper = modalTypes.reduce((helpers, type) => (
  {
    ...helpers,
    [`show${type}Message`]: (opts = {}) => (
      Promise.resolve(store.dispatch(addModal(
        { ...opts, [type.toLowerCase()]: true, modalType: type.toLowerCase() },
      )))
    ),
  }
), {});

export default ModalApp;
