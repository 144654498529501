import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v as bvVar } from 'bv';
import { routing as routingSelectors } from 'redux-selectors';

const { makeIsCurrentPath } = routingSelectors;
const isCurrentPath = makeIsCurrentPath();

const sections = bvVar('sectionsMapping');

const useCurrentSection = () => {
  const location = useLocation();
  const store = useStore();
  const getMatchedKey = () => (
    sections.find(({ pathname }) => isCurrentPath(store.getState(), pathname))?.sectionKey || 'otherSections');
  const [currentSection, setCurrentSection] = useState(getMatchedKey());

  useEffect(() => {
    if (location.pathname === '/') {
      setCurrentSection('home');
      return;
    }
    setCurrentSection(getMatchedKey());
  }, [location.pathname]);

  return currentSection;
};

export default useCurrentSection;
