import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useFetch, useLoggedIn } from 'bv-hooks';
import { set as setComponent, setTitle } from 'src/app/layout_apps/right_sidebar/duck';
import { homeProduct } from 'global-config';
import { repositionNavItems } from '../helpers';
import NavigationButtonContainer from './navigation_button_container';
import fetchButtons from '../api/fetch_buttons';
import useCurrentSection from '../hooks/use_current_section';

const NavigationButtons = () => {
  const dispatch = useDispatch();
  const loggedIn = useLoggedIn();
  const [buttons, isLoading] = useFetch(fetchButtons, [loggedIn]);
  const currentSection = useCurrentSection();

  const repositionedNavItems = useMemo(
    () => repositionNavItems(buttons, currentSection === 'home' ? homeProduct : currentSection), [currentSection, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      const buttonWithComponent = buttons.find((button) => button.component);
      if (buttonWithComponent) {
        dispatch(setComponent(buttonWithComponent.component));
        dispatch(setTitle(buttonWithComponent.label));
      }
    }
  }, [isLoading]);

  return (
    <div className="floating-nav__list">
      {!isLoading && repositionedNavItems.map((button) => (
        <NavigationButtonContainer key={button.id} {...button} />
      ))}
    </div>
  );
};

export default NavigationButtons;
