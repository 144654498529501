import { PureComponent, createRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

class Badge extends PureComponent {
  constructor(props) {
    super(props);

    this.onAnimationEnd = this.onAnimationEnd.bind(this);

    this.state = {
      updated: false,
    };

    this.badgeRef = createRef();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { badge } = this.props;
    if (nextProps.badge !== badge) {
      this.setState({
        updated: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.badgeRef.current) {
      this.badgeRef.current.addEventListener('animationend', this.onAnimationEnd);
    }
  }

  onAnimationEnd() {
    this.badgeRef.current.removeEventListener('animationend', this.onAnimationEnd);

    this.setState({
      updated: false,
    });
  }

  render() {
    const { badge, className } = this.props;
    const { updated } = this.state;

    if (badge === 0) { return null; }

    const badgeClassName = classnames(`${className}-link-badge`, 'floating-nav__item--badge', {
      'floating-nav__item--badge-updated': updated,
    });

    return (
      <div className="floating-nav__item--badge-container">
        <div ref={this.badgeRef} className={badgeClassName} id={`js-${className}-link-badge`}>
          {badge}
        </div>
      </div>
    );
  }
}

Badge.propTypes = {
  badge: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
};

const mapStateToProps = (state, ownProps) => ({
  badge: state.floatingNavigation[ownProps.badgeName],
});

export default connect(mapStateToProps)(Badge);
