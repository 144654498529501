import { getJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';
import { loggedIn } from 'bv-helpers/session';

export default () => (
  getJSON('/api/left_components', {
    c: locale(),
    l: loggedIn(),
  })
    .catch(() => [])
);
