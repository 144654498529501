import { ADD_MODAL, REMOVE_MODAL } from './actions';

const initialState = {
  modals: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_MODAL:
      return {
        modals: [
          ...state.modals,
          { ...action.modal, id: state.modals.length },
        ],
      };
    case REMOVE_MODAL:
      return { modals: state.modals.slice(0, -1) };
    default:
      return state;
  }
};
