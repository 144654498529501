import PropTypes from 'prop-types';
import { t } from 'bv-i18n';

import { Icon } from 'bv-components';

import Link from './link';

const LinkItemSingle = ({
  className,
  href,
  onClick,
  icon,
  small,
  iconClassName,
  label,
  badges,
  nofollow,
}) => {
  const content = (
    <>
      {icon && <Icon id={icon} big={!small} className={iconClassName} />}
      {label}
      {badges.map((badge) => (
        <div
          className={`left-component__link-item-label is-${badge}`}
          key={badge}
        >
          {t(`badges.${badge}`)}
        </div>
      ))}
    </>
  );

  return (
    href ? (
      <Link
        nofollow={nofollow}
        className={className}
        href={href}
      >
        {content}
      </Link>
    ) : (
      <span className={className} onClick={onClick}>{content}</span>
    )
  );
};

LinkItemSingle.propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
  className: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  badges: PropTypes.instanceOf(Array),
  nofollow: PropTypes.bool,
};

LinkItemSingle.defaultProps = {
  small: false,
  href: null,
  onClick: null,
  badges: [],
  nofollow: false,
};

export default LinkItemSingle;
