import { v as bvVar } from 'bv';
import { getJSON } from 'bv-fetch';
import { accountNumber } from 'bv-helpers/session';

export default () => (
  getJSON('/api/left_components/favorite_sports_links', {
    c: bvVar('locale'),
    l: accountNumber(),
  })
    .catch(() => [])
);
