import PropTypes from 'prop-types';

import Links from './links';

const LinksComponent = ({ links }) => (
  <Links className="left-component links-component" links={links} />
);

LinksComponent.propTypes = {
  links: PropTypes.instanceOf(Array).isRequired,
};

export default LinksComponent;
