import { t } from 'bv-i18n';

export const SET = 'layoutApps/rightSidebar/SET';
export const SET_TITLE = 'layoutApps/rightSidebar/SET_TITLE';
export const HIDE = 'layoutApps/rightSidebar/HIDE';
export const SHOW = 'layoutApps/rightSidebar/SHOW';

export const set = (component) => ({
  type: SET,
  component,
});

export const setTitle = (title) => ({
  type: SET_TITLE,
  title,
});

export const hide = () => ({
  type: HIDE,
});

export const show = () => ({
  type: SHOW,
});

const initialState = {
  visible: false, // Used when the right sidebar visibility is toggleable
  component: null,
  title: null,
};

const componentsData = (component) => ({
  cashout: {
    title: t('javascript.cashout.cashout'),
  },
  betslip: {
    title: t('javascript.betslip'),
  },
}[component]);

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        component: action.component,
        ...componentsData(action.component),
      };
    case SET_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case HIDE:
      return {
        ...state,
        visible: false,
      };
    case SHOW:
      return {
        ...state,
        visible: true,
      };
    default:
      return state;
  }
};
