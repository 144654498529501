import { createSelector } from 'reselect';
import { identity } from 'underscore';

import { makeIsActive as RightSidebarMakeIsActive, getComponentDisabled } from '../right_sidebar/selectors';

const getComponent = (state, props) => props.component;

// makeIsActive from RightSidebar is not used directly because
// the idea is that an icon could be active for other reasons
// RightSidebar is only about the content shown in the RightSidebar
// I can imagine we may want to mark as active a button if it's href coincides
const makeIsActive = () => {
  const isActive = RightSidebarMakeIsActive();

  return createSelector(
    identity,
    getComponent,
    isActive,
  );
};
export {
  getComponentDisabled,
  makeIsActive,
};
