import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFetch, useFeature } from 'bv-hooks';
import { selectLoggedIn } from 'session-selectors';
import fetchLeftComponents from './api/fetch_left_components';
import LeftComponentsView from './left_components_view';

const LeftComponentsContainer = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const [components, loading] = useFetch(fetchLeftComponents, [loggedIn]);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [, intercomResolved] = useFeature('intercom_live_chat');

  return (
    <LeftComponentsView
      components={components}
      loading={loading || !intercomResolved}
      subMenuOpen={subMenuOpen}
      setSubMenuOpen={setSubMenuOpen}
    />
  );
};

export default LeftComponentsContainer;
