import PropTypes from 'prop-types';

const LinkRow = ({ wrap, children }) => (wrap
  ? <div className="left-component__row">{children}</div>
  : children);

export default LinkRow;

LinkRow.propTypes = {
  wrap: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
  ]).isRequired,
};
