import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sanitize } from 'dompurify';
import { Modal } from 'bv-components';
import { removeModal } from '../actions';

const concatFunctions = (...args) => () => {
  args.forEach((value) => value && value());
};

const decorateActions = (actions, closeFn, removeFn) => {
  if (actions) {
    return actions.map((action) => {
      if (action.close) {
        return Object.assign(action, { onClick: concatFunctions(action.onClick, closeFn) });
      } if (action.remove) {
        return Object.assign(action, { onClick: concatFunctions(action.onClick, removeFn) });
      }

      return action;
    });
  }

  return [];
};

const ModalApp = ({ modals, dispatchRemoveModal }) => (
  modals.length
    ? modals.map((modal) => {
      const closeFn = concatFunctions(dispatchRemoveModal, modal.onClose);

      return (
        <Modal
          {...modal}
          actions={decorateActions(modal.actions, closeFn, dispatchRemoveModal)}
          onCloseClick={closeFn}
          key={modal.id}
        >
          {modal.message
          && <p>{modal.message}</p>}
          {modal.html
          && <div dangerouslySetInnerHTML={{ __html: sanitize(modal.html) }} />}
          {modal.children}
        </Modal>
      );
    }) : null
);

ModalApp.propTypes = {
  modals: PropTypes.arrayOf(Object).isRequired,
  dispatchRemoveModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => state.modal;

const mapDispatchToProps = (dispatch) => ({
  dispatchRemoveModal: () => {
    dispatch(removeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalApp);
