import PropTypes from 'prop-types';

import { withFetching } from 'bv-hocs';
import fetchFavoriteSportsLinks from '../api/fetch_favorite_sports_links';
import Links from './links';

export const MyFavoriteSportsComponent = ({ links, label }) => links.length > 0 && (
  <div className="left-component favsports-component">
    <div className="favsports-component__title">{label}</div>
    <Links links={links} small />
  </div>
);

MyFavoriteSportsComponent.propTypes = {
  label: PropTypes.string.isRequired,
  links: PropTypes.instanceOf(Array).isRequired,
};

export default withFetching(
  fetchFavoriteSportsLinks,
  'links',
  { showSpinner: false },
)(MyFavoriteSportsComponent);
