import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { withFold } from 'bv-hocs';
import { routing as routingSelectors } from 'redux-selectors';

import Expand from './expand';
import LinkItemSingle from './link_item_single';
import LinkItemGroup from './link_item_group';
import LinkRowWrapper from './link_row_wrapper';

const { makeIsCurrentPath } = routingSelectors;

export const LinkItem = ({
  children,
  children_links: childrenLinks,
  folded,
  href,
  icon,
  isCurrentPath,
  label,
  small,
  toggleFolded,
  badges,
  nofollow,
}) => {
  const className = classnames('left-component__link-item', {
    active: isCurrentPath,
    'left-component__link-item--small': small,
    'left-component__link-item--with-icon': icon,
  });

  const wrapperClassName = classnames('left-component__link-item-wrapper', {
    active: !folded,
  });

  const iconClassName = classnames('left-component__link-icon', {
    'is-active': isCurrentPath,
  });

  const hasChildren = childrenLinks.length > 0;

  return (
    <div className={wrapperClassName}>
      <LinkRowWrapper wrap={hasChildren}>
        {href
          ? (
            <LinkItemSingle
              href={href}
              icon={icon}
              small={small}
              label={label}
              className={className}
              iconClassName={iconClassName}
              badges={badges}
              nofollow={nofollow}
            />
          ) : (
            <LinkItemGroup
              className={className}
              hasChildren={hasChildren}
              toggleFolded={toggleFolded}
              icon={icon}
              small={small}
              iconClassName={iconClassName}
              label={label}
            />
          )}
        {hasChildren && <Expand onClick={toggleFolded} />}
      </LinkRowWrapper>
      {children}
    </div>
  );
};

LinkItem.propTypes = {
  children: PropTypes.element,
  children_links: PropTypes.instanceOf(Array),
  folded: PropTypes.bool.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isCurrentPath: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
  toggleFolded: PropTypes.func.isRequired,
  badges: PropTypes.instanceOf(Array),
  nofollow: PropTypes.bool,
};

LinkItem.defaultProps = {
  children: null,
  children_links: [],
  small: false,
  badges: [],
  icon: '',
  nofollow: false,
};

const makeMapStateToProps = () => {
  const isCurrentPath = makeIsCurrentPath();

  return (state, props) => ({
    isCurrentPath: props.href && isCurrentPath(state, props.href),
  });
};

export default connect(makeMapStateToProps)(withFold(LinkItem));
