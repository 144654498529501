import PropTypes from 'prop-types';

import { Icon } from 'bv-components';
import { withFold } from 'bv-hocs';
import Subnavigation from './subnavigation';

const WithSubnavigationComponent = ({
  folded, toggleFolded, label, links, nextIsTwin, setSubMenuOpen,
}) => {
  const onToggleFold = () => {
    setSubMenuOpen(folded);
    toggleFolded();
  };

  return (
    <div className={`left-component with-subnavigation-component ${nextIsTwin ? ' next-is-twin' : ''}`}>
      <div className="left-component__row " onClick={onToggleFold}>
        <span className="left-component__link-item">{label}</span>
        <Icon className="left-components__right-icon bvs-link" />
      </div>
      <Subnavigation
        folded={folded}
        toggleFolded={onToggleFold}
        label={label}
        links={links}
      />
    </div>
  );
};

WithSubnavigationComponent.propTypes = {
  label: PropTypes.string.isRequired,
  nextIsTwin: PropTypes.bool.isRequired,
  toggleFolded: PropTypes.func.isRequired,
  setSubMenuOpen: PropTypes.func.isRequired,
  folded: PropTypes.bool.isRequired,
  links: PropTypes.instanceOf(Array).isRequired,
};

export default withFold(WithSubnavigationComponent);
