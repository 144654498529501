import { t } from 'bv-i18n';

import { Icon } from 'bv-components';
import Link from './link';

const LoginComponent = () => (
  <div className="left-component login-component">
    <Icon className="left-component__link-icon login-component__user-icon" big />
    <Link className="login-component__link" href="/login">{t('login')}</Link>
    <Link className="login-component__link login-component__signup-link" href="/account/new">{t('open_account')}</Link>
  </div>
);

export default LoginComponent;
