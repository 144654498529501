import PropTypes from 'prop-types';

import { reset } from '../../floating_navigation/helpers';
import withLocale from '../helpers/with_locale';
import { noFollowProp, targetProp } from '../helpers/helpers';

const Link = ({
  className, href, nofollow, children,
}) => (
  <a
    {...noFollowProp(nofollow)}
    {...targetProp(href)}
    className={`left-components__link${className ? ` ${className}` : ''}`}
    href={withLocale(href ? href.replace(/.?open_in_new_tab=1$/g, '') : '')}
    onClick={reset}
  >
    {children}
  </a>
);

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  nofollow: PropTypes.bool,
};

Link.defaultProps = {
  className: null,
  nofollow: false,
};

export default Link;
