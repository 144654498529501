import { compose } from 'underscore';
import {
  openMenu as doOpenMenu,
  openRight as doOpenRight,
  reset as doReset,
  setBetslipBetsNumber as doSetBetslipBetsNumber,
  setBetslipTooltip as doSetBetslipTooltip,
  setCashoutBetsNumber as doSetCashoutBetsNumber,
  incrementCashoutBetsNumber as doIncrementCashoutBetsNumber,
  decrementCashoutBetsNumber as doDecrementCashoutBetsNumber,
} from './duck';

const { store } = window.reduxState;

export const openMenu = compose(store.dispatch, doOpenMenu);
export const openRight = compose(store.dispatch, doOpenRight);
export const reset = compose(store.dispatch, doReset);
export const setBetslipBetsNumber = compose(store.dispatch, doSetBetslipBetsNumber);
export const setBetslipTooltip = compose(store.dispatch, doSetBetslipTooltip);
export const setCashoutBetsNumber = compose(store.dispatch, doSetCashoutBetsNumber);
export const incrementCashoutBetsNumber = compose(store.dispatch, doIncrementCashoutBetsNumber);
export const decrementCashoutBetsNumber = compose(store.dispatch, doDecrementCashoutBetsNumber);

export const repositionNavItems = (items, currentSection) => {
  const repositioned = items?.filter(
    (navItem) => navItem.positions[currentSection])
    .toSorted((a, b) => a.positions[currentSection] - b.positions[currentSection]);
  if (!repositioned) return [];
  return repositioned;
};
