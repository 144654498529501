/* eslint react/no-array-index-key: off */
import PropTypes from 'prop-types';
import { locale } from 'bv-i18n';
import { buildRatingStarsClasses } from './helpers';

const Rating = ({ rating, numberOfReviews }) => (
  <>
    <div className="smartbanner__info-stars">
      { buildRatingStarsClasses(rating).map((starClass, index) => <div key={`smartbanner-star-${index}`} className={`smartbanner__info-stars__star ${starClass}`} />) }
    </div>
    {
      numberOfReviews
      && (
      <span className="smartbanner__info-reviews">
        (
        {numberOfReviews.toLocaleString(locale())}
        )
      </span>
      )
    }
  </>
);

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  numberOfReviews: PropTypes.number.isRequired,
};

export default Rating;
