import reducer from './duck';
import FloatingNavigation from './components/floating_navigation';

const { addReducers } = window.reduxState;

addReducers({
  floatingNavigation: reducer,
});

// eslint-disable-next-line import/no-webpack-loader-syntax
require('expose-loader?FloatingNavigationHelpers!./helpers');

const FloatingNavigationApp = () => (
  <FloatingNavigation />
);

export default FloatingNavigationApp;
