import { useState, useEffect } from 'react';
import { RenderInBody } from 'bv-components';
import { v as bvVar } from 'bv';
import SmartAppBanner from './smart_app_banner';
import { bannerIsVisible } from './helpers';

const smartAppBannerData = bvVar('smartAppBanner');

const SmartAppBannerApp = () => {
  const { visibility, ...rest } = smartAppBannerData || {};
  const [showBanner, setShowBanner] = useState(bannerIsVisible(visibility));

  useEffect(() => {
    if (smartAppBannerData) {
      document.body.classList[showBanner ? 'add' : 'remove']('smartbanner--show');
    }
  }, [showBanner]);

  if (!smartAppBannerData || !showBanner) return null;

  return (
    <RenderInBody>
      <SmartAppBanner
        setShowBanner={setShowBanner}
        {...rest}
      />
    </RenderInBody>
  );
};

export default SmartAppBannerApp;
