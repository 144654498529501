import { loggedIn } from 'bv-helpers/session';
import { getCookie, rafToken, setCookie } from 'bv';
import { isURLAbsolute } from 'bv-helpers';

const visibilityLevels = {
  loggedOut: 0,
  loggedIn: 1,
  always: 2,
};

const validVisibility = (bannerVisibilityLevel) => {
  switch (bannerVisibilityLevel) {
    case visibilityLevels.loggedOut:
      return !loggedIn();
    case visibilityLevels.loggedIn:
      return loggedIn();
    case visibilityLevels.always:
      return true;
    default:
      return false;
  }
};

export const bannerIsVisible = (bannerVisibilityLevel) => !navigator.standalone
    && !getCookie('smartbanner-closed')
    && !getCookie('smartbanner-installed')
    && validVisibility(bannerVisibilityLevel);

export const buildBannerURL = (url) => {
  const currentUrlParams = new URLSearchParams(window.location.search);
  const bannerUrl = new URL(isURLAbsolute(url) ? url : `${window.location.protocol}//${window.location.host}${url}`);

  const requiredCookies = ['btag', 'btagid', 'affid'];
  const rafCookie = rafToken();

  // append current URL params to smart banner URL
  currentUrlParams.forEach((value, key) => {
    bannerUrl.searchParams.append(key, value);

    // set required cookies for 24 hours
    if (requiredCookies.includes(key)) {
      setCookie(key, value, '/', 86400000);
    }
  });

  if (!bannerUrl.pathname.toLowerCase().includes('&raf=') && !!rafCookie) {
    bannerUrl.searchParams.append('raf', rafCookie);
  }

  return bannerUrl.toString();
};

export const buildRatingStarsClasses = (rating) => [0, 1, 2, 3, 4].map((star) => {
  if (rating > star + 0.6) return 'smartbanner__info-stars__star-full';
  if (rating > star) return 'smartbanner__info-stars__star-half';
  return 'smartbanner__info-stars__star-empty';
});
