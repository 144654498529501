import PropTypes from 'prop-types';

import { Icon } from 'bv-components';
import Link from './link';

const UpsellComponent = ({
  icon, title, subtitle, links,
}) => (
  <div className="left-component upsell-component">
    <div className="flex flex--centered">
      <Icon className="upsell-component__icon flex--no-shrink" id={icon} type="upsell-component-icon" big />
      <div>
        <div className="upsell-component__title">
          {title}
        </div>
        {subtitle && (
          <div className="upsell-component__subtitle">
            {subtitle}
          </div>
        )}
      </div>
    </div>

    {links.length > 0 && (
      <div>
        {links.map((link) => (
          <Link
            href={link.href}
            key={link.id}
            className="upsell-component__link"
          >
            <span>{link.label}</span>
          </Link>
        ))}
      </div>
    )}
  </div>
);

UpsellComponent.propTypes = {
  icon: PropTypes.string.isRequired,
  links: PropTypes.instanceOf(Array).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

UpsellComponent.defaultProps = {
  subtitle: null,
};

export default UpsellComponent;
