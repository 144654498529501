import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { difference } from 'underscore';
import classnames from 'classnames';
import { v as bvVar } from 'bv';

import { Icon } from 'bv-components';
import { handleCta } from 'bv-services';

import Badge from './badge';
import Tooltip from './tooltip';

const screenSizes = ['Small', 'Medium', 'Large', 'X-Large'];
const getHiddenClasses = (layout) => {
  const visibleSizes = layout.split('$');
  const hiddenSizes = difference(screenSizes, visibleSizes);
  return hiddenSizes.map((size) => `floating-nav__item--hidden-${size.toLocaleLowerCase().replace('-', '')}`);
};

const rightFloatingIconMapping = {
  default: 'inverted',
  1: ' ',
  2: ' ',
};

const NavigationButtonView = (props) => {
  const {
    active, disabled, cta, icon, hasBadge, hasTooltip, label, layout,
  } = props;

  const queryString = `label=${label}&path=floating_navigation`;

  const onClick = useCallback(() => {
    if (disabled) return;

    handleCta({ ...cta, queryString });
  }, [disabled]);

  const className = classnames('floating-nav__item', getHiddenClasses(layout), {
    'floating-nav__item--active': active,
    'bvs-link': !disabled,
    'floating-nav__item--disabled': disabled,
  });

  return (
    <div className={className} onClick={onClick}>
      <Icon id={icon} type="floating-nav" />
      <Icon
        id={icon}
        className="floating-nav__medium-icon"
        {...{ [active ? 'active' : (rightFloatingIconMapping[bvVar('brandId')] || rightFloatingIconMapping.default)]: true }}
      />
      {hasBadge && <Badge {...props} />}
      {hasTooltip && <Tooltip {...props} />}
      <span className="floating-nav__item-text">{label}</span>
    </div>
  );
};

NavigationButtonView.propTypes = {
  label: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  hasBadge: PropTypes.bool.isRequired,
  hasTooltip: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  cta: PropTypes.instanceOf(Object).isRequired,
  layout: PropTypes.string.isRequired,
};

export default NavigationButtonView;
