/* eslint import/no-named-as-default: off */
import PropTypes from 'prop-types';

import LinkItem from './link_item';
import LiveChat from './live_chat';

const Component = {
  LIVECHAT: LiveChat,
};

const Links = ({
  links, className, small,
}) => (
  links.length > 0 && (
    <div className={className}>
      {links.map((link) => {
        const LinkComponent = Component[link.type] || LinkItem;
        return (
          <LinkComponent {...link} key={link.href} small={small}>
            {link.children_links && link.children_links.length > 0 && (
              <Links className="link-item-children" links={link.children_links} />
            )}
          </LinkComponent>
        );
      })}
    </div>
  )
);

Links.propTypes = {
  className: PropTypes.string,
  links: PropTypes.instanceOf(Array),
  small: PropTypes.bool,
};

Links.defaultProps = {
  small: false,
  links: [],
  className: null,
};

export default Links;
