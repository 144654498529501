import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { sanitize } from 'dompurify';

const Tooltip = ({
  text, tooltipName, duration, tooltipClassName,
}) => {
  const [shown, setShown] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    let shownTimer = null;
    let hiddenTimer = null;
    if (text !== '') {
      shownTimer = window.setTimeout(() => {
        setShown(true);
        setHidden(false);
        hiddenTimer = window.setTimeout(() => setHidden(true), 500);
      }, duration);
    }
    return () => {
      window.clearTimeout(shownTimer);
      window.clearTimeout(hiddenTimer);
      setShown(false);
      setHidden(false);
    };
  }, [text]);

  if (text === '') { return null; }

  const currentClassName = classnames('', {
    'tooltip-appear-active': !shown && !hidden,
    'tooltip-leave-active': shown && !hidden,
    'tooltip-leave': shown && hidden,
  });

  return (
    <div className={`floating-nav__item--tooltip-container ${tooltipClassName} ${currentClassName}`}>
      <div
        className="floating-nav__item--tooltip"
        id={`js-${tooltipName}-link-tooltip`}
        dangerouslySetInnerHTML={{ __html: sanitize(text) }}
      />
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  duration: PropTypes.number,
  tooltipClassName: PropTypes.string,
  tooltipName: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  duration: 4000,
  tooltipClassName: '',
};

const mapStateToProps = (state, ownProps) => ({
  text: state.floatingNavigation[ownProps.tooltipName],
  duration: state.floatingNavigation[`${ownProps.tooltipName}Duration`],
  tooltipClassName: state.floatingNavigation[`${ownProps.tooltipName}ClassName`],
});

export default connect(mapStateToProps)(Tooltip);
