import PropTypes from 'prop-types';
import { Button, Link } from 'bv-components';
import { setCookie } from 'bv';
import { buildBannerURL } from './helpers';
import Rating from './rating';
import Padlock from './padlock';

const SmartAppBanner = ({
  setShowBanner,
  rating,
  numberOfReviews,
  padlockText,
  daysHidden,
  daysReminder,
  deviceClass,
  iconUrl,
  url,
  title,
  showRating,
  buttonText,
}) => {
  const hideBanner = (reason, days) => {
    if (!days) return null;

    return () => {
      setCookie(`smartbanner-${reason}`, true, '/', days * 86400000);
      setShowBanner(false);
    };
  };

  const closeBanner = hideBanner('closed', daysHidden || 15);
  const installBanner = hideBanner('installed', daysReminder);

  return (
    <div className={`bvs-card smartbanner ${deviceClass}`}>
      <span className="smartbanner__close" onClick={closeBanner}>×</span>
      <img
        className="smartbanner__icon"
        alt="Smart Banner Icon"
        src={iconUrl}
      />
      <div className="smartbanner__info">
        <div className="smartbanner__info-title">{title}</div>
        <div className="smartbanner__info-rating">
          {
              showRating && (rating
                ? <Rating rating={rating} numberOfReviews={numberOfReviews} />
                : <Padlock padlockText={padlockText} />)
            }
        </div>
      </div>
      <Link className="smartbanner__button" to={buildBannerURL(url)} onClick={installBanner}>
        <Button
          label={buttonText}
          secondary
        />
      </Link>
    </div>
  );
};

SmartAppBanner.propTypes = {
  deviceClass: PropTypes.string.isRequired,
  rating: PropTypes.number,
  numberOfReviews: PropTypes.number,
  iconUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  showRating: PropTypes.bool.isRequired,
  daysHidden: PropTypes.number,
  daysReminder: PropTypes.number,
  setShowBanner: PropTypes.func.isRequired,
  padlockText: PropTypes.string,
};

SmartAppBanner.defaultProps = {
  daysHidden: 15,
  daysReminder: 0,
  padlockText: null,
  rating: 0,
  numberOfReviews: 0,
};

export default SmartAppBanner;
