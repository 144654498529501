import PropTypes from 'prop-types';

import { Icon } from 'bv-components';

const LinkItemGroup = ({
  className, hasChildren, toggleFolded, icon, small, iconClassName, label,
}) => (
  <span className={className} onClick={hasChildren ? toggleFolded : false}>
    {icon && <Icon id={icon} big={!small} className={iconClassName} />}
    {label}
  </span>
);

LinkItemGroup.propTypes = {
  hasChildren: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
  className: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  toggleFolded: PropTypes.func.isRequired,
};

LinkItemGroup.defaultProps = {
  hasChildren: false,
  small: false,
};

export default LinkItemGroup;
