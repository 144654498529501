/* eslint import/no-named-as-default: off */
import PropTypes from 'prop-types';

import { Icon } from 'bv-components';
import Links from './links';

const Subnavigation = ({
  folded, toggleFolded, label, links,
}) => (
  <div className={`left-subnavigation${folded ? '' : ' active'}`}>
    <div className="left-subnavigation__title">
      <Icon className="left-subnavigation__left-icon bvs-link" onClick={toggleFolded} />
      {label}
    </div>
    <Links className="left-subnavigation__content has-scroll-bars" links={links} />
  </div>
);

Subnavigation.propTypes = {
  folded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  links: PropTypes.instanceOf(Array).isRequired,
  toggleFolded: PropTypes.func.isRequired,
};

export default Subnavigation;
