import PropTypes from 'prop-types';
import { show } from '@intercom/messenger-js-sdk';
import { useFeature } from 'bv-hooks';
import { zendesk } from 'bv-services';
import LinkItemSingle from './link_item_single';

const LiveChat = ({
  label, icon, small, badges,
}) => {
  const [intercomEnabled] = useFeature('intercom_live_chat');

  return (
    <div className="left-component__link-item-wrapper">
      <LinkItemSingle
        onClick={intercomEnabled ? show : zendesk.liveChat}
        icon={icon}
        small={small}
        label={label}
        className="left-components__link left-component__link-item"
        iconClassName="left-component__link-icon"
        badges={badges}
      />
    </div>
  );
};

LiveChat.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  small: PropTypes.bool,
  badges: PropTypes.instanceOf(Array),
};

LiveChat.defaultProps = {
  small: false,
  badges: [],
};

export default LiveChat;
