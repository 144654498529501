import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon, Spinner } from 'bv-components';
import { reset } from '../floating_navigation/helpers';

import * as componentTypes from './component_types';

const { store } = window.reduxState;

const nextIsTwin = (components, index) => (
  components.length > index + 1
    && componentTypes[components[index].type] === componentTypes[components[index + 1].type]
);

const LeftComponentsView = ({
  components, loading, subMenuOpen, setSubMenuOpen,
}) => {
  const mainClassName = classnames('left-sidebar', { loading });
  const className = classnames('left-components has-scroll-bars', { 'is-disabled': subMenuOpen });

  return (
    <div className={mainClassName}>
      {loading
        ? <Spinner />
        : (
          <>
            <div className="left-components__back" onClick={reset}>
              <Icon className="left-components__back-icon" />
            </div>
            <Provider store={store}>
              <div className={className} id="left-components">
                {components.map((component, index) => {
                  const Component = componentTypes[component.type];

                  return Component
                    ? (
                      <Component
                        {...component.settings}
                        key={component.id}
                        nextIsTwin={nextIsTwin(components, index)}
                        setSubMenuOpen={setSubMenuOpen}
                      />
                    )
                    : null;
                })}
              </div>
            </Provider>
          </>
        )}
    </div>
  );
};

LeftComponentsView.propTypes = {
  components: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool.isRequired,
  subMenuOpen: PropTypes.bool.isRequired,
  setSubMenuOpen: PropTypes.func.isRequired,
};

export default LeftComponentsView;
