import PropTypes from 'prop-types';

const Expand = ({ onClick }) => (
  <div className="left-components__expand bvs-link" onClick={onClick}>
    <span />
    <span />
    <span />
  </div>
);

Expand.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Expand;
