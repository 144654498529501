import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { reset } from '../duck';

const anyColumnOpen = (floatingNavigation) => floatingNavigation.stateLeftPanel
  || floatingNavigation.stateRightPanel;

const CloseLayer = ({
  reset: doReset,
  floatingNavigation,
}) => anyColumnOpen(floatingNavigation) && (
  <div className="floating-nav__close" onClick={doReset} />
);

const mapStateToProps = (state) => ({
  floatingNavigation: state.floatingNavigation,
});

const mapDispatchToProps = (dispatch) => ({
  reset() { dispatch(reset()); },
});

CloseLayer.propTypes = {
  floatingNavigation: PropTypes.instanceOf(Object).isRequired,
  reset: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloseLayer);
