import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pick } from 'underscore';
import {
  disableById,
  enableById,
  disableByClassName,
  enableByClassName,
} from 'bv-services/body-scroll-manager';

const applyStateStyle = (state, classNameFunc) => {
  Object.entries(pick(state, 'left', 'right')).forEach((entry) => {
    document.documentElement.classList[
      entry[1] ? 'add' : 'remove'
    ](classNameFunc(entry[0]));
  });
};

class PanelStylesApplicator extends PureComponent {
  componentDidMount() {
    this.applyStyles();
  }

  componentDidUpdate() {
    this.applyStyles();
  }

  applyStyles() {
    applyStateStyle(this.props, (state) => `${state}-state`);
    const { left } = this.props;

    if (left) {
      disableById('left-components');
      disableByClassName('left-subnavigation__content');
    } else {
      enableById('left-components');
      enableByClassName('left-subnavigation__content');
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  left: state.floatingNavigation.stateLeftPanel,
  right: state.floatingNavigation.stateRightPanel,
});

PanelStylesApplicator.propTypes = {
  left: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(PanelStylesApplicator);
